import React, { useState,useEffect } from "react";
import "./ChangePassword.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Axios from "../../Helpers/Interceptors";
import { Table, Button, Space, Switch } from "antd";
import { toast } from "react-toastify";
const ChangePassword = () => {
  const [initialValues] = useState({
    oldPassword: "",
    password: "",
    confirmpassword:""
  });
  const [userData, setuserData] = useState({});
  useEffect(() => {
    const data=JSON.parse(localStorage.getItem('userData'))?.data?.user

     setuserData(  data);


  }, []);

  const handleSubmit = async (values,resetForm) => {

    try {
      let obj = {
        oldPassword:values.oldPassword,
        newPassword:values.password
      }
      let response = await Axios.post(`manageUsers/changePassword/${userData.id}`, obj);
      if (response.status === 200) {
        toast.success('Password changed successfully.');
        resetForm()

      } else if (response.status === 401 || response.status === 403 || response.status === 400) {
        toast.error(response.response.data.message);
      }
      toast.error( response?.response?.data);
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred. Please try again.');

    }
  };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .matches(/^\S*$/, "Password should not contain spaces *")
      .required("Old Password is required *"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters *")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter *")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter *")
      .matches(/[0-9]/, "Password must contain at least one number *")
      .matches(/^\S*$/, "Password should not contain spaces *")
      .matches(
        /[^A-Za-z0-9]/,
        "Password must contain at least one special character *"
      )
      .matches(/^\S*$/, "Password should not contain spaces *")
      .required("Password is required *"),

    confirmpassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match *")
      .required("Confirm Password is required *"),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  return (
    <div className="container-fluid">
      <div className="signup-content1">
      <div className="heading-content">
        <h2>Change Password</h2>
      </div>
      <div className="container">
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values,resetForm);

        }}
      >
        {({ errors, touched }) => (
          <Form id="signup-form" autoComplete="off">
            <div className="form-group">
              <div className="input-group">
                <Field autoComplete="new-password"
                  className={`form-input ${
                    errors.oldPassword && touched.oldPassword ? "error" : ""
                  }`}
                  name="oldPassword"
                  type={showPassword2 ? "text" : "password"}
                  placeholder="Old Password*"
                />
                <i
                  className={`far ${showPassword2 ? "fa-eye" : "fa-eye-slash"}`}
                  onClick={() => setShowPassword2(!showPassword2)}
                  style={{
                    cursor: "pointer",
                    marginLeft: "-30px",
                    marginTop: "24px",
                  }}
                ></i>
              </div>
              <ErrorMessage
                name="oldPassword"
                component="span"
                className="error-message"
              />
            </div>
            <div className="form-group">
              <div className="input-group">
                <Field autoComplete="off"
                  className={`form-input ${
                    errors.password && touched.password ? "error" : ""
                  }`}
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="New Password*"
                />
                <i
                  className={`far ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    cursor: "pointer",
                    marginLeft: "-30px",
                    marginTop: "24px",
                  }}
                ></i>
              </div>
              <ErrorMessage
                name="password"
                component="span"
                className="error-message"
              />
            </div>

            <div className="form-group">
              <div className="input-group">
                <Field autoComplete="off"
                  className={`form-input ${
                    errors.confirmpassword && touched.confirmpassword
                      ? "error"
                      : ""
                  }`}
                  name="confirmpassword"
                  type={showPassword1 ? "text" : "password"}
                  placeholder="Confirm Password*"
                />
                <i
                  className={`far ${showPassword1 ? "fa-eye" : "fa-eye-slash"}`}
                  onClick={() => setShowPassword1(!showPassword1)}
                  style={{
                    cursor: "pointer",
                    marginLeft: "-30px",
                    marginTop: "24px",
                  }}
                ></i>
              </div>
              <ErrorMessage
                name="confirmpassword"
                component="span"
                className="error-message"
              />
            </div>

            <div className="submit-container">

                      <button className="submit-button1" type="submit">
                        Save
                      </button>


                </div>
          </Form>
        )}
      </Formik>
      </div>
      </div>
    </div>
  );
};

export default ChangePassword;
