import React from 'react';
import './TopBar.scss';
import { Dropdown, Space, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons'; // Import Ant Design icons

const TopBar = ({ toggleSidebar }) => {
  const navigate = useNavigate();

  const items = [
    {
      label: (
        <Space>
          <UserOutlined />
          <span>My-Account</span>
        </Space>
      ),
      key: 'account',
      onClick: () => navigate('/my-account'), // Navigate to user profile
    },
    {
      type: 'divider',
    },
    {
      label: (
        <Space>
          <LogoutOutlined />
          <span>Logout</span>
        </Space>
      ),
      key: 'logout',
      onClick: () => logout(), // Navigate to home
    },
  ];

  const logout=()=>{
    localStorage.clear()
    navigate('/')
  }


  return (
    <div className="home-section" style={{ position: 'sticky', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div className="home-content">
        <i className='bx bx-menu' onClick={toggleSidebar}></i>
        {/* <img
          className='image-height'
          src="/images/doctor-logo.png"
          style={{ height: "100px" }}
          alt="Logo"
        /> */}
      </div>
      <div className="profile-section">
        <Dropdown
          overlay={<Menu items={items} />}
          trigger={['click']}
        >
          <div onClick={(e) => e.preventDefault()}>
            <Space>
            <img
          className='profile-img'
          src="/images/dummy.png"
          alt="Logo"
        />

            </Space>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default TopBar;
