import React, { useState } from 'react';
import { Table, Button, Space, Switch } from 'antd';
import './Events.scss';

const Events = () => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const dataSource = [
    {
      key: '1',
      lastName: 'Doe',
      firstName: 'John',
      number: '(123) 456-7890',
      email: 'abc@gmail.com',
    },
    {
      key: '2',
      lastName: 'Smith',
      firstName: 'Jane',
      number: '(987) 654-3210',
      email: 'abc@gmail.com',
    },
  ];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },

    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Button type="primary" className="edit-btn">Edit</Button>
          <Switch
          checked={expandedRowKeys.includes(record.key)}
          onChange={() => toggleRow(record.key)}
        />
        </Space>
      ),
    },
  ];

  const toggleRow = (key) => {
    setExpandedRowKeys((prevKeys) =>
      prevKeys.includes(key) ? prevKeys.filter(k => k !== key) : [...prevKeys, key]
    );
  };

  return (


    <div className="signup">
      <div className="container1">
    <div className="signup-content1">
      <h2>Events</h2>
      <div className="add-user-button-container">
        {/* <Button type="primary" className="add-user-btn" style={{marginRight:'auto'}}>Add User</Button> */}
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
      />

    </div>
    </div>
    </div>
  );
};

export default Events;
