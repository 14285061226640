import React from "react";
import error from "../../assests/image.png";

const Error = () => {
  return <div className="container-fluid">
    <img src={error} alt="404" className="error-image" />
  </div>
};

export default Error;

