import Register from '../Components/Register/Register';
import Login from '../Components/Login/Login';
import ForgotPassword from '../Components/ForgotPassword/ForgotPassword';
import UserManagment from '../Components/UserManagment/UserManagment';
import Home from '../Components/Home/Home';
import ResetPassword from '../Components/ResetPassword/ResetPassword';
import UserProfile from '../Components/UserProfile/UserProfile';
import Error from '../Components/Error/Error';
import MyAccount from '../Components/MyAccount/MyAccount';
import Events from '../Components/Events/Events';
import ChangePassword from '../Components/ChangePassword/ChangePassword';
import GernateOTP from '../Components/GernateOTP/GernateOTP';

export const routes = () => [
  { path: '/', element: Login , pvtRoute: false }, // Public route (login)

  {
    path: 'forgot-password',
    element: ForgotPassword,
  },
  {
    path: 'register',
    element: Register,pvtRoute: false
  },
  {
    path: 'my-account',
    element: MyAccount,
    pvtRoute: true, // Protected route
  },
  {
    path: 'home',
    element: Home,
    pvtRoute: true,
  },
  {
    path: 'reset-password/:id',
    element: ResetPassword,pvtRoute: false
  },
  {
    path: 'change-password',
    element: ChangePassword,
    pvtRoute: true,
  },
  {
    path: 'gernate-otp',
    element: GernateOTP,
    pvtRoute: true,
  },
  {
    path: 'user-profile',
    element: UserProfile,
    pvtRoute: true,
  },
  {
    path: 'user-managment',
    element: UserManagment,
    pvtRoute: true,
  },
  {
    path: 'events',
    element: Events,
    pvtRoute: true,
  },
  {
    path: '*',
    element: Error, // 404 error page
  },
];
