import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { Modal, Input } from 'antd';
import { toast } from "react-toastify";
import Axios from "../../Helpers/Interceptors";
import Loader from '../Loader/Loader';
const GenerateOTP = () => {
  const [loading, setloading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    selection: 'email',
    email: '',
    phoneNumber: ''
  });

  const [userData, setuserData] = useState({});
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const showModal = () => {
    setConfirmModalOpen(true);
  };

  const cancelToggle = () => {
    setConfirmModalOpen(false);
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('userData'))?.data?.user;
    console.log(data);
    setuserData(data);

    // if (data) {
    //   setInitialValues({
    //     email: data.email || "",
    //     phoneNumber: data.phoneNumber || "",
    //   });
    // }
  }, []);

  // Mask email to format ***@domain.com
  const maskEmail = (email) => {
    const [localPart, domain] = email.split('@');
    return `${localPart.slice(0, 3)}***@${domain}`;
  };

  // Mask phone number to format *******123
  const maskPhoneNumber = (phoneNumber) => {
    return phoneNumber.slice(0, -3).replace(/\d/g, '*') + phoneNumber.slice(-3);
  };
  const handleSubmit= async (value)=>{

    try {
      const obj ={
        type:value.selection,
      }
      if(obj.type=='email'){
        obj.email=userData.email
      }else{
        obj.phoneNumber=userData.phoneNumber
      }
      setloading(true);
      const response = await Axios.get(`manageUsers/getOtp/${userData.id}`);
      if (response.status === 200) {
        toast.success(`OTP sent to your ${obj.type =='email' ? 'email address' : 'number'}`);
        cancelToggle()

      } else if (response.status === 401 || response.status === 403) {
        toast.error(response.response.data.message);
      }
      setloading(false);
    } catch (error) {
      // Handle CORS error or other network issues
      console.error('Error:', error);

      // If error is a CORS issue, you might not have a response object
      if (error.response) {
        toast.error(`Error: ${error.response.data.message || 'An error occurred. Please try again later.'}`);
      } else if (error.request) {
        toast.error('Request was made but no response was received. This could be a CORS issue or network problem.');
      } else {
        toast.error('An error occurred. Please try again later.');
      }
      toast.error('Request was made but no response was received. This could be a CORS issue or network problem.');
      setloading(false);
    }
  }


  return (
    <>
    { loading && <Loader />}
    <div className="container-fluid">
      <div className="signup-content1">
        <div className="heading-content">
          <h2>Generate OTP</h2>
        </div>
        <button
          type="button"
          onClick={showModal}
          className="submit-button3"
          style={{ marginLeft: "10px" }}
        >
          Generate OTP
        </button>
      </div>

      <Modal
        open={confirmModalOpen}
        onCancel={cancelToggle}
        footer={null}
        maskClosable={false}
      >
        <div className="signup-content-model1">
        <div className="heading-content">
          <h2>Send OTP</h2>
        </div>

        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            handleSubmit(values)
           console.log(values); // Handle form submission here
          }}
        >
          {({ setFieldValue, values }) => (
            <Form >
              <div>
                <h2>Select Method</h2>
              </div>

              {/* Email Option with Masked Email */}
              <div className="mb-2 d-flex">
                <input
                  style={{ marginRight: '10px' }}
                  type="radio"
                  name="selection"
                  value="email"
                  onChange={() => setFieldValue('selection', 'email')}
                  checked={values.selection === 'email'}
                />
                <Field name="email">
                  {({ field }) => (
                    <Input
                      {...field}
                      style={{ padding: '15px' }}
                      value={maskEmail(userData?.email || "")}
                      readOnly
                    />
                  )}
                </Field>
              </div>

              {/* Phone Number Option with Masked Phone Number */}
              <div className="mb-2 d-flex">
                <input
                  style={{ marginRight: '10px' }}
                  type="radio"
                  name="selection"
                  value="number"
                  onChange={() => setFieldValue('selection', 'number')}
                  checked={values.selection === 'number'}
                />
                <Field name="phoneNumber">
                  {({ field }) => (
                    <Input
                      {...field}
                      style={{ padding: '15px' }}
                      value={maskPhoneNumber(userData?.phoneNumber || "")}
                      readOnly
                    />
                  )}
                </Field>
              </div>

              {/* Submit and Close Buttons */}
              <div className="submit-container1" style={{ marginTop: '20px' }}>
                <button className="submit-button1" type="submit">
                  Send
                </button>
                <button
                  className="submit-button2"
                  type="button"
                  onClick={cancelToggle}
                >
                  Close
                </button>
              </div>
            </Form>
          )}
        </Formik>
        </div>
      </Modal>
    </div>
    </>
  );
};

export default GenerateOTP;
