// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-section {
  position: relative;
  background: #ffffff;
  height: auto;
  left: 260px;
  width: calc(100% - 260px);
  transition: all 0.5s ease;
  padding: 1rem;
}

.sidebar.close ~ .home-section {
  left: 78px;
  width: calc(100% - 78px);
}

.home-section .home-content {
  height: auto;
  display: flex;
  align-items: center;
}

.home-section .home-content .bx-menu,
.home-section .home-content .text {
  color: #11101d;
  font-size: 35px;
}

.home-section .home-content .bx-menu {
  margin: 0 15px;
  cursor: pointer;
}

.home-section .home-content .text {
  font-size: 26px;
  font-weight: 600;
}

@media (max-width: 420px) {
  .sidebar.close .nav-links li .sub-menu {
    display: none;
  }
}
.image-height {
  max-height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Events/Events.scss"],"names":[],"mappings":"AACA;EAEE,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EACA,yBAAA;EACA,aAAA;AADF;;AAGA;EACE,UAAA;EACA,wBAAA;AAAF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;AACF;;AACA;;EAEE,cAAA;EACA,eAAA;AAEF;;AAAA;EACE,cAAA;EACA,eAAA;AAGF;;AADA;EACE,eAAA;EACA,gBAAA;AAIF;;AAFA;EACE;IACE,aAAA;EAKF;AACF;AADA;EACE,gBAAA;AAGF","sourcesContent":["\r\n.home-section {\r\n\r\n  position: relative;\r\n  background: #ffffff;\r\n  height: auto;\r\n  left: 260px;\r\n  width: calc(100% - 260px);\r\n  transition: all 0.5s ease;\r\n  padding: 1rem;\r\n}\r\n.sidebar.close ~ .home-section {\r\n  left: 78px;\r\n  width: calc(100% - 78px);\r\n}\r\n.home-section .home-content {\r\n  height: auto;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n.home-section .home-content .bx-menu,\r\n.home-section .home-content .text {\r\n  color: #11101d;\r\n  font-size: 35px;\r\n}\r\n.home-section .home-content .bx-menu {\r\n  margin: 0 15px;\r\n  cursor: pointer;\r\n}\r\n.home-section .home-content .text {\r\n  font-size: 26px;\r\n  font-weight: 600;\r\n}\r\n@media (max-width: 420px) {\r\n  .sidebar.close .nav-links li .sub-menu {\r\n    display: none;\r\n  }\r\n}\r\n\r\n\r\n.image-height{\r\n  max-height: 50px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
