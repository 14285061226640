import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route,useLocation } from 'react-router-dom';
import PvtRoute from './Helpers/PvtRoute';
import { routes } from './Helpers/Routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from './Components/SideBar/SideBar'; // Import your sidebar
import "./index.css";
import TopBar from './Components/TopBar/TopBar';
// import 'antd/dist/antd.css'; // Import Ant Design styles


const App = () => {

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const showSidebarAndTopbar = ![
      '/', '/register', '/forgot-password'
    ].includes(location.pathname) && !location.pathname.startsWith('/reset-password');
    return (
      <div className={`app ${isSidebarOpen ? 'sidebar-open' : ''}`}> {/* Add class based on sidebar state */}
        {showSidebarAndTopbar && <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />}
        {showSidebarAndTopbar && <TopBar toggleSidebar={toggleSidebar} />}

        <div className={` ${showSidebarAndTopbar ? 'home-section1' : ''}`}> {/* Main content wrapper */}
          <ToastContainer />
          <Routes>
            {routes()?.map((val, i) => (
              <Route
                key={i}
                path={val.path}
                element={

                    <PvtRoute
                      children={val}
                      path={val.path?.split("/")[1]}
                    />

                }
              />
            ))}
          </Routes>
        </div>
      </div>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
