import React, { useState, useRef } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import "./UserProfile.scss";
import { Formik, Form, Field,ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Axios from "../../Helpers/Interceptors";
const UerProfile = () => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",

    firstName: "",
    lastName: "",

    phoneNumber: "",
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required *")
      .email("Email is invalid"),

    password: Yup.string()
      .min(6, "Password must be at least 6 characters *")
      .required("Password is required *"),

    firstName: Yup.string()
      .required("First name is required *")
      .min(2, "First name must be at least 2 characters *"), // Adjusted length
    lastName: Yup.string() // Fixed typo from lasttname to lastname
      .min(2, "Last name must be at least 2 characters *") // Adjusted length
      .required("Last name is required *"),

    phoneNumber: Yup.string()
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits *") // Matches exactly 10 digits
      .required("Phone number is required *"),
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (value) => {


    try {
        const response = await Axios.post('auth/register', value);
        if (response.status == 200) {  // Checks if status code is 200 (OK)
          toast.success('Register Successfully');
            navigate("/home")
        } else {
          toast.error('Registration failed. Please try again.');
        }


    } catch (error) {
        console.error('Error:', error);
        toast.error(error);
    }
  };

  return (
    <div className="main">
      <section className="signup">
        <div className="container1">
          <div className="signup-content1 ">
            <Formik
              validationSchema={validationSchema}
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={(values, { resetForm }) => {
                handleSubmit(values); // Use your custom submit logic here
                resetForm();
              }}
            >
              {({ errors, touched, setFieldValue }) => (
              <Form id="signup-form" className="signup-form" autoComplete="off">
              <div className="register-logo">
                <img src="/images/doctor-logo.png" style={{ height: "100px" }} alt="Logo" />
                <h2 className="form-title">Create Account</h2>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <Field  autoComplete="off"
                      className={`form-input ${errors.firstName && touched.firstName ? "error" : ""}`}
                      name="firstName"
                      placeholder="First Name"
                    />
                    <ErrorMessage name="firstName">
                      {(msg) => (
                        <span className="error-message">
                          <i className="fa-solid fa-circle-info fa-xl"></i>
                          {msg}
                        </span>
                      )}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <Field autoComplete="off"
                      className={`form-input ${errors.lastName && touched.lastName ? "error" : ""}`}
                      name="lastName"
                      placeholder="Last Name"
                    />
                    <ErrorMessage name="lastName">
                      {(msg) => (
                        <span className="error-message">
                          <i className="fa-solid fa-circle-info fa-xl"></i>
                          {msg}
                        </span>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
              <div className="form-group">
                <Field
                  className={`form-input ${errors.phoneNumber && touched.phoneNumber ? "error" : ""}`}
                  name="phoneNumber"
                  placeholder="Phone Number"
                />
                <ErrorMessage name="phoneNumber">
                  {(msg) => (
                    <span className="error-message">
                      <i className="fa-solid fa-circle-info fa-xl"></i>
                      {msg}
                    </span>
                  )}
                </ErrorMessage>
              </div>
</div>
<div className="col-md-6">
              <div className="form-group">
                <Field
                  className={`form-input ${errors.email && touched.email ? "error" : ""}`}
                  name="email"
                  placeholder="Email"
                />
                <ErrorMessage name="email">
                  {(msg) => (
                    <span className="error-message">
                      <i className="fa-solid fa-circle-info fa-xl"></i>
                      {msg}
                    </span>
                  )}
                </ErrorMessage>
              </div>
</div>
</div>
              <div className="form-group">
                <div className="input-group">
                  <Field autoComplete="off"
                    className={`form-input ${errors.password && touched.password ? "error" : ""}`}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                  />
                  <i
                    className={`far ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ cursor: "pointer", marginLeft: "-30px", marginTop:'24px' }}
                  ></i>
                </div>
                <ErrorMessage name="password">
                  {(msg) => (
                    <span className="error-message">
                      {msg}
                    </span>
                  )}
                </ErrorMessage>
              </div>

              <div className="form-group">
                <button className="form-submit" type="submit">
                  Sign up
                </button>
              </div>


            </Form>


              )}
            </Formik>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UerProfile;
