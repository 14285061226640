import React, { useState,useEffect } from 'react';
import { Table, Button, Switch,Modal,Dropdown, Space, Menu  } from 'antd';
import { useNavigate } from "react-router";
import './UserManagment.scss';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import Axios from "../../Helpers/Interceptors";
import * as Yup from "yup";
import { UserOutlined } from '@ant-design/icons';
import Loader from '../Loader/Loader';
const UserManagement = () => {

  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [type, setType] = useState('Add User');
  const [dataSource, setdataSource] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);  // State to manage confirmation modal visibility
  const [userToToggle, setUserToToggle] = useState(null);  //
  const [filteredData, setFilteredData] = useState([]); // State to hold filtered data
  const [searchText, setSearchText] = useState('');
  const showModal = (data) => {
    setSelectedRow(data);
    setOpen(true);
    setType('Edit User')
  };

  const deleteUserConfirmation = (data) => {
    setUserToToggle(data);
    setopenDeleteModal(true);

  };


  const items = [
    {
      label: (
        <Space>
          <UserOutlined />
          <span>Admin</span>
        </Space>
      ),
      key: 'account',
      onClick: () => assign(), // Navigate to user profile
    },
    {
      type: 'divider',
    },
    {
      label: (
        <Space>
          <UserOutlined />
          <span>Contributor</span>
        </Space>
      ),
      key: 'logout',
      onClick: () => assign(), // Navigate to home
    },
  ];


  const handleCancel = (resetForm) => {
    resetForm(); // Reset the form
    setOpen(false); // Close the modal
  };

  const closeModal = () => {
    setInitialValues({
      email: "",
      password: "",

      firstName: "",
      lastName: "",

      phoneNumber: "",
    })
    setOpen(false); // Close the modal
  };


  useEffect(() => {
    getUser();
  }, []);

const assign=()=>{

}

const getUser =async ()=>{
  setloading(true)
    try {
      const response = await Axios.get(`manageUsers/getUsers`);
      if (response.status === 200) {
        setloading(false)
        setdataSource(response.data);
        setFilteredData(response.data)

      } else if (response.status === 401 || response.status === 403) {
        setloading(false)
        toast.error(response.response.data.message);
      }
    } catch (error) {
      setloading(false)
      console.error('Error:', error);
      toast.error('An error occurred. Please try again later.');
    }
}

const confirmToggle = async () => {

  try {
    setloading(true)
    const response = await Axios.post(`manageUsers/toggleStatus/${userToToggle.id}`, {  });
    if (response.status === 200) {
      setloading(false)
      toast.success('User status updated successfully');
      setConfirmModalOpen(false);  // Close the confirmation modal
      getUser();  // Refresh the data
    } else {
      setloading(false)
      toast.error('Failed to update user status');
    }
  } catch (error) {
    console.error('Error:', error);
    toast.error('An error occurred. Please try again later.');
  }
};

const cancelToggle = () => {

  setUserToToggle(null);
  setConfirmModalOpen(false);
  getUser(); // Close the confirmation modal without any changes
};
const cancelDeleteToggle = () => {

  setUserToToggle(null);
  setopenDeleteModal(false);
 // Close the confirmation modal without any changes
};


  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },

    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space>
            <Link >
          <Button type="primary" className="button-content"  onClick={() => { setInitialValues({
      email: "",
      password: "",

      firstName: "",
      lastName: "",

      phoneNumber: "",
    });showModal(record); setType('Edit User'); }}><i className="bx bx-edit" ></i> Edit</Button>
     <Button type="primary" className="button-content"  onClick={() => { deleteUserConfirmation(record) }}><i className="bx bx-trash" ></i> Delete</Button>
     <Dropdown
          overlay={<Menu items={items} />}
          trigger={['click']}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>
            <Button type="primary" className="button-content"  ><i className="bx bx-user-check" ></i> Role  <i className="bx bx-chevron-down" ></i> </Button>

            </Space>
          </a>
        </Dropdown>
    </Link>
          <Switch
            checked={record.loginAllowed}
            onChange={(event) => handleToggle(record,event)}
          />
        </Space>
      ),
    },
  ];



  const deleteUser =async ()=>{

    try {
      setloading(true)
        let response = await Axios.post(`manageUsers/deleteUser/${userToToggle.id}`, {});
        if (response.status == 200) {  // Checks if status code is 200 (OK)
          toast.success('User Delete Successfully');
          getUser();
          setloading(false)
          cancelDeleteToggle()

        } else {
          setloading(false)
          toast.error('Registration failed. Please try again.');
        }




    } catch (error) {
      console.error('Error:', error);
      toast.error(error);
    }
  }
  const handleToggle = (record,event) => {
    console.log(event)
    record.loginAllowed=event
    setUserToToggle(record);  // Set the user whose status we're toggling
    setConfirmModalOpen(true);  // Open the confirmation modal
  };

  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",

    firstName: "",
    lastName: "",

    phoneNumber: "",
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required *")
      .email("Email is invalid"),

      firstName: Yup.string()
      .required("First name is required *")
      .min(2, "First name must be at least 2 characters *")
      .matches(/^[A-Za-z ]*$/, "Please enter alphabetic characters only *"),

    lastName: Yup.string()
      .required("Last name is required *")
      .min(2, "Last name must be at least 2 characters *")
      .matches(/^[A-Za-z ]*$/, "Please enter alphabetic characters only *"),

    phoneNumber: Yup.string()
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits *") // Matches exactly 10 digits
      .required("Phone number is required *"),
  });



  const handleSubmit = async (value,reset) => {

    const url= type == 'Add User' ? 'manageUsers/register' : 'manageUsers/editUser'
    try {
      setloading(true)
      if(type == 'Add User'){
        let response = await Axios.post(url, value);
        if (response.status == 200) {  // Checks if status code is 200 (OK)
          toast.success('User Add Successfully');
          getUser();
          setloading(false)
          navigate("/user-managment")
          closeModal();
          reset()
        } else {
          setloading(false)
          toast.error('Registration failed. Please try again.');
        }
      }else{

        let response = await Axios.put(url, value);
        if (response.status == 200) {  // Checks if status code is 200 (OK)
          toast.success('User Edit Successfully');
          getUser();
          navigate("/user-managment")
          setloading(false)
          closeModal();
        } else {
          setloading(false)
          toast.error('Registration failed. Please try again.');
        }
      }




    } catch (error) {
      setloading(false)
      console.error('Error:', error);
      toast.error(error);
    }
  };

  const handleSearch = () => {
    const searchValue = searchText.toLowerCase();
    const filteredResults = dataSource.filter(
      (user) =>
        user.firstName.toLowerCase().includes(searchValue) ||
        user.lastName.toLowerCase().includes(searchValue) ||
        user.email.toLowerCase().includes(searchValue)
    );
    setFilteredData(filteredResults);
  };

  return (
    <>
    { loading && <Loader />}
    <div className="container-fluid">
      <div className="heading-content">
        <h2>User Management</h2>
      </div>
      <div className="add-button form-container">
      <div className="form-group1" style={{marginRight: '18px'}}>
                      <input className='form-control' style={{width: '400px'}} placeholder='Search...' value={searchText}
            onChange={(e) => setSearchText(e.target.value)}></input>

                    </div>
                    <Button type="primary" className="add-user-btn"style={{marginRight: 'auto',padding:'18px'}}  onClick={handleSearch}><i className="bx bx-search"></i> search</Button>
      <Link  >
          <Button type="primary" className="add-user-btn" style={{padding:'18px'}}onClick={() => { showModal(); setType('Add User');  }}><i className="bx bx-plus"></i> Add User</Button>
        </Link>
        </div>
      <div className="main-contents table-responsive">
        <Table
          dataSource={filteredData}
          columns={columns}
          className="table-container"
        />

      </div>



      <Modal
        open={open}
        onCancel={closeModal}
        destroyOnClose={true}
        footer={null}
        maskClosable={false} // Prevent modal from

      >
        <div className="signup-content-model">
          <div className="heading-content">
            <h2>{type}</h2>
          </div>
          <div className="avatar-upload">
            <div className="avatar-edit">
              <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" />
              <label for="imageUpload"></label>
            </div>
            <div className="avatar-preview">
              <div id="imagePreview">
              </div>
            </div>
          </div>
          <Formik
            validationSchema={validationSchema}
            enableReinitialize={true}
            initialValues={selectedRow || initialValues}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values,resetForm); // Use your custom submit logic here

            }}
          >
            {({ errors, touched, setFieldValue,resetForm  }) => (
              <Form id="signup-form" className="signup-form container" autoComplete="off">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <Field autoComplete="off"  onChange={(e) => setFieldValue("firstName", e.target.value.trimStart())}
                        className={`form-input ${errors.firstName && touched.firstName ? "error" : ""}`}
                        name="firstName"
                        placeholder="First Name*"
                      />
                      <ErrorMessage name="firstName">
                        {(msg) => (
                          <span className="error-message">
                            <i className="fa-solid fa-circle-info fa-xl"></i>
                            {msg}
                          </span>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <Field autoComplete="off"  onChange={(e) => setFieldValue("lastName", e.target.value.trimStart())}
                        className={`form-input ${errors.lastName && touched.lastName ? "error" : ""}`}
                        name="lastName"
                        placeholder="Last Name*"
                      />
                      <ErrorMessage name="lastName">
                        {(msg) => (
                          <span className="error-message">
                            <i className="fa-solid fa-circle-info fa-xl"></i>
                            {msg}
                          </span>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <Field autoComplete="off"
                        className={`form-input ${errors.phoneNumber && touched.phoneNumber ? "error" : ""}`}
                        name="phoneNumber"
                        placeholder="Phone Number*"
                      />
                      <ErrorMessage name="phoneNumber">
                        {(msg) => (
                          <span className="error-message">
                            <i className="fa-solid fa-circle-info fa-xl"></i>
                            {msg}
                          </span>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Field autoComplete="off"
                        className={`form-input ${errors.email && touched.email ? "error" : ""}`}
                        name="email"
                        placeholder="Email*"
                      />
                      <ErrorMessage name="email">
                        {(msg) => (
                          <span className="error-message">
                            <i className="fa-solid fa-circle-info fa-xl"></i>
                            {msg}
                          </span>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  {/* <div className="input-group">
                    <Field autoComplete="off"
                      className={`form-input ${errors.password && touched.password ? "error" : ""}`}
                      name="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                    />
                    <i
                      className={`far ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
                      onClick={() => setShowPassword(!showPassword)}
                      style={{ cursor: "pointer", marginLeft: "-30px", marginTop: '24px' }}
                    ></i>
                  </div>
                  <ErrorMessage name="password">
                    {(msg) => (
                      <span className="error-message">
                        {msg}
                      </span>
                    )}
                  </ErrorMessage> */}
                </div>
                <div className="submit-container1" style={{ marginTop: '20px' }}>

                <button className="submit-button1 " type="submit">
                    Submit
                  </button>
                  <button className="submit-button2" type="button"  onClick={() => handleCancel(resetForm)}>
                    Close
                  </button>
                </div>

                {/* Custom Cancel Button */}


              </Form>
            )}
          </Formik>
        </div>
      </Modal>
      <Modal
        open={confirmModalOpen}
        onCancel={cancelToggle}
        destroyOnClose={true}
        footer={null}
        maskClosable={false}
      >
        <div className="signup-content-model1">
        <p>Are you sure you want to change the admin approval status for this user?</p>
        <div className="submit-container1" style={{ marginTop: '20px' }}>

<button className="submit-button1 " type="submit" onClick={confirmToggle}>
    Yes
  </button>
  <button className="submit-button2" type="button"  onClick={() => cancelToggle()}>
    No
  </button>
</div>
        </div>

      </Modal>



      <Modal
        open={openDeleteModal}
        onCancel={cancelDeleteToggle}
        destroyOnClose={true}
        footer={null}
        maskClosable={false}
      >
        <div className="signup-content-model1">
        <p>Are you sure to delete this record?</p>
        <div className="submit-container1" style={{ marginTop: '20px' }}>

<button className="submit-button1 " type="submit" onClick={deleteUser}>
    Yes
  </button>
  <button className="submit-button2" type="button"  onClick={() => cancelDeleteToggle()}>
    No
  </button>
</div>
        </div>

      </Modal>
    </div>
    </>

  );
};

export default UserManagement;
