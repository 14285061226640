import React from 'react'
import SideBar from "../SideBar/SideBar"
import TopBar from '../TopBar/TopBar'
import "./Home.scss"

const Home = () => {
    return (
        <div className="home-container">
           asd
        </div>
    )
}

export default Home
