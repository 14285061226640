import React from "react";

import error from "../../assests/loader.gif";

const Loader = () => {
  return (
    <div className="bg_viewpopup container-fluid" style={{ zIndex: "999999" }}>
      <div style={{position: 'absolute',
    top: '50%',
    left: '50%;',
    width: '4rem',left: '50%'}}>
                <img src={error} alt="404" className="error-image" />
      </div>
    </div>
  );
};

export default Loader;