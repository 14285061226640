// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-section .home-content {
  height: 60px;
  display: flex;
  align-items: center;
}

.home-section .home-content .bx-menu,
.home-section .home-content .text {
  color: #fff;
  font-size: 35px;
}

.home-section .home-content .bx-menu {
  margin: 0 15px;
  cursor: pointer;
}

.home-section .home-content .text {
  font-size: 26px;
  font-weight: 600;
}

.profile-img {
  border-radius: 50px;
  height: 50px;
  width: 50px;
}

@media (max-width: 420px) {
  .sidebar.close .nav-links li .sub-menu {
    display: none;
  }
}
.image-height {
  max-height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/Components/TopBar/TopBar.scss"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;AAAF;;AAEA;;EAEE,WAAA;EACA,eAAA;AACF;;AACA;EACE,cAAA;EACA,eAAA;AAEF;;AAAA;EACE,eAAA;EACA,gBAAA;AAGF;;AADA;EACE,mBAAA;EACA,YAAA;EACA,WAAA;AAIF;;AAFA;EACE;IACE,aAAA;EAKF;AACF;AADA;EACE,gBAAA;AAGF","sourcesContent":["\r\n.home-section .home-content {\r\n  height: 60px;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n.home-section .home-content .bx-menu,\r\n.home-section .home-content .text {\r\n  color: #fff;\r\n  font-size: 35px;\r\n}\r\n.home-section .home-content .bx-menu {\r\n  margin: 0 15px;\r\n  cursor: pointer;\r\n}\r\n.home-section .home-content .text {\r\n  font-size: 26px;\r\n  font-weight: 600;\r\n}\r\n.profile-img{\r\n  border-radius: 50px;\r\n  height: 50px;\r\n  width: 50px;\r\n}\r\n@media (max-width: 420px) {\r\n  .sidebar.close .nav-links li .sub-menu {\r\n    display: none;\r\n  }\r\n}\r\n\r\n\r\n.image-height{\r\n  max-height: 50px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
