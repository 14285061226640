import React from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const PvtRoute = ({ path, children: Component }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("token");

  // Check if the user is logged in or not
  if (!token) {
    // If the user is not logged in and tries to visit restricted pages
    if (
      location.pathname === "/" ||
      location.pathname === "/register" ||
      location.pathname.includes("/reset-password") ||
      location.pathname === "/forgot-password"
    ) {
      return <Component.element />; // Allow access to login, register, reset-password, or forgot-password pages
    } else {
      // Redirect to the login page if the user is not logged in and tries to access any other page

      return <Navigate to="/" />;
    }
  } else {
    // If the user is logged in and tries to visit restricted pages
    if (
      location.pathname === "/" ||
      location.pathname === "/register" ||
      location.pathname.includes("/reset-password") ||
      location.pathname === "/forgot-password"
    ) {

      // Redirect to the previous route or the home page

      ; // This will navigate to the previous route
      return    <Navigate to="/my-account" />; // Render nothing as we're navigating away
    } else {
      // If the user is logged in, allow access to the protected route
      return <Component.element />;
    }
  }
};

export default PvtRoute;
