// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-container {
  display: flex;
  height: 100vh;
}
.home-container .main-content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.home-container .main-content .content-area {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/Home/Home.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;AACJ;AACI;EACI,SAAA;EACA,aAAA;EACA,sBAAA;AACR;AACQ;EACI,SAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACZ","sourcesContent":[".home-container {\r\n    display: flex;\r\n    height: 100vh; // Full height of the viewport\r\n\r\n    .main-content {\r\n        flex: 1; // Take remaining space\r\n        display: flex;\r\n        flex-direction: column; // Stack TopBar and content area\r\n\r\n        .content-area {\r\n            flex: 1; // Take up available space below TopBar\r\n            display: flex;\r\n            justify-content: center; // Center the Login component\r\n            align-items: center; // Center vertically\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
