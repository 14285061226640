import React, { useState, useRef } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import "./Register.scss";
import { Formik, Form, Field,ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Loader from '../Loader/Loader';
import Axios from "../../Helpers/Interceptors";
const Register = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);

  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",

    firstName: "",
    lastName: "",

    phoneNumber: "",
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required *")
      .email("Email is invalid"),

    password: Yup.string()
      .min(8, "Password must be at least 8 characters *")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter *")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter *")
      .matches(/[0-9]/, "Password must contain at least one number *")
      .matches(/[^A-Za-z0-9]/, "Password must contain at least one special character *")
      .matches(/^\S*$/, "Password should not contain spaces *")
      .required("Password is required *"),

      firstName: Yup.string()
      .required("First name is required *")
      .min(2, "First name must be at least 2 characters *")
      .matches(/^[A-Za-z ]*$/, "Please enter alphabetic characters only *"),

    lastName: Yup.string()
      .required("Last name is required *")
      .min(2, "Last name must be at least 2 characters *")
      .matches(/^[A-Za-z ]*$/, "Please enter alphabetic characters only *"),
      phoneNumber: Yup.string()

      .matches(/^[0-9]{10}$/, "Please Enter the valid Phone Number *") // Only 10 digits allowed
      .required("Phone number is required *")
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (value,reset) => {


    try {
      setloading(true)
      const response = await Axios.post('auth/register', value);
      if (response.status == 200) {  // Checks if status code is 200 (OK)
        setloading(false)
        toast.success('Register Successfully');
          navigate("/")
          reset()
      } else if(response.status == 409) {

        setloading(false)
        toast.error(response.response.data);
      }


  } catch (error) {
    console.error('Error:', error);

    // If error is a CORS issue, you might not have a response object
    if (error.response) {
      toast.error(`Error: ${error.response.data.message || 'An error occurred. Please try again later.'}`);
      console.log(`Error: ${error.response.data.message || 'An error occurred. Please try again later.'}`);
    } else if (error.request) {
      toast.error('Request was made but no response was received. This could be a CORS issue or network problem.');
      console.log('Request was made but no response was received. This could be a CORS issue or network problem.');
    } else {
      console.log('An error occurred. Please try again later.')
      toast.error('An error occurred. Please try again later.');
    }

    setloading(false);
  }
  };

  return (
    <>
     { loading && <Loader />}
     <div className="main">
     <div className="signup">
        {/* <div className="container"> */}
          <div className="signup-content">
            <Formik
              validationSchema={validationSchema}
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={(values, { resetForm }) => {
                handleSubmit(values,resetForm); // Use your custom submit logic here

              }}
            >
              {({ errors, touched, setFieldValue }) => (
                <Form id="signup-form" className="signup-form" autoComplete="off">
                  <div className="register-logo">
                    <img
                      src="/images/doctor-logo.png"
                      style={{ height: "100px" }}
                      alt="Logo"
                    />
                    <h2>Create Account</h2>
                  </div>

                  <div className="form-group">
                    <Field autoComplete="new-password"   onChange={(e) => setFieldValue("firstName", e.target.value.trimStart())}
                      className={`form-input ${
                        errors.firstName && touched.firstName ? "error" : ""
                      }`}
                      name="firstName"
                      placeholder="First Name*"
                    />
                    <ErrorMessage name="firstName">
                      {(msg) => (
                        <span className="error-message">
                          <i className="fa-solid fa-circle-info fa-xl"></i>
                          {msg}
                        </span>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="form-group">
                    <Field autoComplete="new-password" onChange={(e) => setFieldValue("lastName", e.target.value.trimStart())}
                      className={`form-input ${
                        errors.lastName && touched.lastName ? "error" : ""
                      }`}
                      name="lastName"
                      placeholder="Last Name*"
                    />
                    <ErrorMessage name="lastName">
                      {(msg) => (
                        <span className="error-message">
                          <i className="fa-solid fa-circle-info fa-xl"></i>
                          {msg}
                        </span>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="form-group">
                    <Field autoComplete="new-password"
                      className={`form-input ${
                        errors.phoneNumber && touched.phoneNumber ? "error" : ""
                      }`}
                      name="phoneNumber"
                      placeholder="Phone Number*"
                    />
                    <ErrorMessage name="phoneNumber">
                      {(msg) => (
                        <span className="error-message">
                          <i className="fa-solid fa-circle-info fa-xl"></i>
                          {msg}
                        </span>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="form-group">
                    <Field autoComplete="new-password"
                      className={`form-input ${
                        errors.email && touched.email ? "error" : ""
                      }`}
                      name="email"
                      placeholder="Email*"
                    />
                    <ErrorMessage name="email">
                      {(msg) => (
                        <span className="error-message">
                          <i className="fa-solid fa-circle-info fa-xl"></i>
                          {msg}
                        </span>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="form-group">
                    <div className="input-group">
                      <Field autoComplete="new-password"
                        className={`form-input ${
                          errors.password && touched.password ? "error" : ""
                        }`}
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password*"
                      />
                      <i
                        className={`far ${
                          showPassword ? "fa-eye" : "fa-eye-slash"
                        }`}
                        onClick={() => setShowPassword(!showPassword)}
                        style={{ cursor: "pointer", marginLeft: "-30px", marginTop:'24px' }}
                      ></i>
                    </div>
                    <ErrorMessage name="password">
                      {(msg) => (
                        <span className="error-message">
                          {/* <i className="fa-solid fa-circle-info fa-xl"></i> */}
                          {msg}
                        </span>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="form-group">
                    <button className="form-submit" type="submit">
                      Sign up
                    </button>
                  </div>

                  <p className="loginhere">
                    Have already an account?{" "}
                    <Link className="loginhere-link" to="/">
                      Login here
                    </Link>
                  </p>
                </Form>
              )}
            </Formik>
          </div>
        {/* </div> */}
      </div>
    </div></>

  );
};

export default Register;
